<template>
    <div class="miniMap">
        <el-button type="primary" class="mini_map_btn" @click="saveFun">
            确认选择
        </el-button>
        <el-amap
            vid="mini-map-contract"
            :zoom="zoom_min"
            :center="mapCenter_min"
            :amap-manager="amapManager"
            :events="mapEvents_min"
        >
            <el-amap-marker
                v-if="marker.position"
                vid="component-marker"
                :icon="marker.icon"
                :position="marker.position"
            ></el-amap-marker>
            <el-amap-info-window
                :auto-move="true"
                :position="Info.position"
                :content="Info.content"
                :offset="Info.offset"
                :visible="Info.visible"
                :events="Info.events"
            >
            </el-amap-info-window>
        </el-amap>
    </div>
</template>
<script>

import VueAMap from 'vue-amap';
const amapManager = new VueAMap.AMapManager();
VueAMap.initAMapApiLoader({
    key: '127a5e9a269ff7f87e8443bf92939ed6',
    plugin: [
        'AMap.AMapManager',
        'AMap.Geocoder',
    ],
    v: '1.4.4',
});

export default {
    name: 'zb-map',
    components: {},
    props: {
        extr: {
            type: [Array, Object, String],
        },
    },
    data() {
        const _that = this;
        return {
            address: '',
            zoom_min: 10,
            // 地图中心点坐标值
            mapCenter_min: [117.120098, 36.6512],
            // 地图管理对象
            amapManager,
            mapEvents_min: {
                init(o) {
                    o.setMapStyle('amap://styles/darkblue');
                },
                click(e) {
                    _that.onMapClick(e);
                },
            },
            marker: {
                icon: require('./images/icon.png'),
                position: null,
            },
            Info: {
                position: [127.120098, 36.6512],
                visible: false,
                content: '',
                offset: [0, -30],
                events: {},
            },
        };
    },
    created() {
        if (this.extr && this.extr.x_coordinate_point && this.extr.y_coordinate_point) {
            this.marker.position = [this.extr.x_coordinate_point, this.extr.y_coordinate_point];
            this.marker.mapCenter_min = [this.extr.x_coordinate_point, this.extr.y_coordinate_point];
        }
    },
    mounted() {},
    activated() {},
    update() {},
    beforeRouteUpdate() {},
    methods: {
        onMapClick(e) {
            if (e.lnglat) {
                this.marker.position = [e.lnglat.lng, e.lnglat.lat];
                this.getAddress([e.lnglat.lng, e.lnglat.lat]);
            } else {
                this.marker.position = null;
            }
        },
        saveFun() {
            if (this.marker.position && this.marker.position[0]) {
                this.extr.savaCabck(this.marker.position, this.address);
                window.hideToast();
            } else {
                this.$message.error('请选择坐标');
            }
        },
        // 根据坐标返回地址(逆地理编码)
        getAddress(points) {
            const AMap = window.AMap;
            const geocoder = new AMap.Geocoder({ radius: 1000 });
            geocoder.getAddress(points, (status, result) => {
                if (status === 'complete' && result.regeocode) {
                    const val = result.regeocode.addressComponent;
                    const s_addr = val.province + val.city + val.district;
                    const addreesd = result.regeocode.formattedAddress; // 全部地址名称
                    const houzhi = addreesd.substring(s_addr.length); // 获取具体街道
                    this.address = s_addr + houzhi + addreesd;
                }
            });
        },
    },
    filter: {},
    computed: {},
    watch: {
        // essentialInfo(newVal) {
        //     if (newVal) {
        //         if (newVal.x_coordinate_point && newVal.y_coordinate_point) {
        //             this.mapCenter_min = [newVal.x_coordinate_point, newVal.y_coordinate_point];
        //             this.marker.position = [newVal.x_coordinate_point, newVal.y_coordinate_point];
        //             this.Info.position = [newVal.x_coordinate_point, newVal.y_coordinate_point];
        //             this.Info.content = newVal.engineering_location;
        //             this.Info.visible = true;
        //         }
        //     }
        // },
    },
};
</script>

<style lang="stylus">
.miniMap
    height 100%
    position relative
    .mini_map_btn
        height .6rem
        position absolute
        z-index 88
        top .2rem
        right .5rem
        bottom 0
    .amap-icon
        img
            width 0.18rem
            height 0.21rem
    .amap-logo
        display none
        opacity 0 !important
    .amap-copyright
        opacity 0
    .amap-info
        .amap-info-contentContainer
            .amap-info-content
                padding 0.1rem
                max-width 1.9rem
                height auto
                border-radius 2px
                font-size 0.12rem
                line-height 0.2rem
                color rgba(255,255,255,0.5)
                background rgba(0, 22, 54, 0.8)
                box-shadow inset 1px 1px 20px 0px rgba(2, 174, 252, 0.8)
            .amap-info-sharp
                border-top-color rgba(2, 174, 252, 0.4)
            .amap-info-close
                top 0.1rem
                right 0.1rem !important
                display none
            .engineering_Info
                width 100%
                height 100%
</style>
